import axios from "../../axios";
import { ClaimContactType } from "../../pages/claims/create/claimSchema";
import { IClaim, IClaimContact, IClaimCreateResponse } from "../../types";
import {
  OrderType,
  SortType,
  PaginatedApiResponse,
} from "../../types/pagination.type";

const claimService = {
  /**
   * Extracts contact info from claim form data.
   * @param claim_data - Full claim object
   * @returns IClaimContact
   */
  extractContactData: (claim_data: any): IClaimContact => {
    let contact_data: IClaimContact;
    if (claim_data.create.claim.who === ClaimContactType.Other) {
      contact_data = {
        contact_name: claim_data.create.claim.contact_name_other,
        phone_number: claim_data.create.claim.phone_number_other,
        email: claim_data.create.claim.email_other,
      };
    } else {
      contact_data = {
        contact_name: claim_data.create.claim.contact_name || "",
        phone_number: claim_data.create.claim.phone_number || "",
        email: claim_data.create.claim.email || "",
      };
    }
    return contact_data;
  },

  /**
   * Submits a claim
   * @param policy_id - Uuid V4
   * @param created_by  - Uuid V4
   * @param contact_data - Claim contact data
   * @param claim_data - Full claim object
   * @returns Claim object
   */
  create: async (
    policy_id: string,
    created_by: string,
    claim_data: any,
  ): Promise<IClaimCreateResponse> => {
    const contact_data = claimService.extractContactData(claim_data);

    const res = await axios.post("/catalog/claims/create", {
      policy_id,
      created_by,
      contact_data,
      claim_data: {
        ...claim_data,
        create: {
          ...claim_data.create,
          claim: {
            ...claim_data.create.claim,
            ...(claim_data.create.claim.who === ClaimContactType.Other
              ? {
                  contact_name_other:
                    claim_data.create.claim.contact_name_other,
                  phone_number_other:
                    claim_data.create.claim.phone_number_other,
                  email_other: claim_data.create.claim.email_other,
                  // Clear out the "Me" contact fields if "Other" was selected, as we don't want server to save them.
                  contact_name: undefined,
                  phone_number: undefined,
                  email: undefined,
                }
              : {
                  ...contact_data,
                  // Clear out the "Other" contact fields if "Me" was selected, as we don't want server to save them.
                  contact_name_other: undefined,
                  phone_number_other: undefined,
                  email_other: undefined,
                }),
          },
        },
      },
    });

    if (res.data && res.data.claim_number) {
      return res.data as IClaimCreateResponse;
    } else {
      throw new Error("Error submitting claim");
    }
  },

  get_by_policy_id: async (policy_id: string): Promise<IClaim[]> => {
    const res = await axios.get(`/catalog/claims/policy_id/${policy_id}`);
    return res.data as IClaim[];
  },

  get_by_claim_id: async (claim_id: string): Promise<IClaim> => {
    const res = await axios.get(`/catalog/claims/${claim_id}`);
    return res.data as IClaim;
  },

  get_all: async (
    offset: number,
    limit: number,
    types: string[] | null | undefined,
  ): Promise<PaginatedApiResponse<IClaim>> => {
    // eslint-disable-next-line no-useless-catch
    try {
      const order_by: OrderType = "desc";
      const sort_type: SortType = "created_at";

      let params = new URLSearchParams({
        order_by,
        sort_type,
        limit: `${limit}`,
        page_number: `${offset}`,
      });

      const res = await axios.get("/catalog/claims", { params });

      if (res.data) {
        return res.data as PaginatedApiResponse<IClaim>;
      } else {
        throw new Error("Error getting all claims");
      }
    } catch (err) {
      throw err;
    }
  },

  search_all: async (
    searchTerm: string,
    offset: number,
    limit: number,
    types: string[] | null | undefined,
  ): Promise<PaginatedApiResponse<IClaim>> => {
    // eslint-disable-next-line no-useless-catch
    try {
      const order_by: OrderType = "desc";
      const sort_type: SortType = "created_at";

      let params = new URLSearchParams({
        searchTerm,
        order_by,
        sort_type,
        limit: `${limit}`,
        page_number: `${offset}`,
      });

      const res = await axios.get("/catalog/claims", { params });

      if (res.data) {
        return res.data as PaginatedApiResponse<IClaim>;
      } else {
        throw new Error("Error getting all claims");
      }
    } catch (err) {
      throw err;
    }
  },
};

export default claimService;
