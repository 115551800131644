import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import {
  SelectOption,
  SelectWithValidation,
} from "../../components/form/SelectWithValidation";
import { Industry } from "../../services/industries/industries.service";
import { Toolkit } from "../../services/toolkits/toolkits.service";
import { useIndustrySelectOptions } from "../../hooks/queries/select_options/useIndustrySelectOptions.query";
import { useToolkitSelectOptions } from "../../hooks/queries/select_options/useToolkitSelectOptions.query";
import { Button, Grid, CheckboxItem, Spinner } from "@safetyculture/sc-web-ui";
import { InputWithValidation } from "../../components/form/InputWithValidation";
import { validation_rules } from "../../components/form/validation_rules";
import { Spacing } from "../../components/layout/Spacing";

export type AutomationFormFields = {
  name: string;
  industries: SelectOption<Industry>[];
  toolkits: SelectOption<Toolkit>[];
  enabled?: boolean;
};

type Props = Partial<AutomationFormFields> & {
  onSubmit: (data: AutomationFormFields) => Promise<boolean>;
  onCancel?: () => void;
  type: "Create" | "Edit";
};

export const AutomationForm: React.FC<Props> = ({
  onSubmit,
  type,
  /* default form values */
  name = "",
  industries = [],
  toolkits = [],
  enabled = false,
  onCancel,
}) => {
  const [has_submitted, setHasSubmitted] = React.useState(false);

  const [industry_search_term, setIndustrySearchTerm] = React.useState("");
  const [toolkit_search_term, setToolkitSearchTerm] = React.useState("");

  const industry_options = useIndustrySelectOptions({ industry_search_term });
  const toolkit_options = useToolkitSelectOptions({ toolkit_search_term });

  const form = useForm<AutomationFormFields>({
    defaultValues: {
      name,
      industries,
      toolkits,
      enabled,
    },
  });

  const handleSubmit = form.handleSubmit(async data => {
    if (!has_submitted) {
      setHasSubmitted(true);
      const success = await onSubmit(data);
      if (!success) {
        setHasSubmitted(false);
      }
    }
  });

  const submit_button_text =
    type === "Create" ? "Create Automation" : "Save Automation";

  return (
    <Grid style={{ maxWidth: 700 }}>
      <Grid.Item xs={12}>
        <InputWithValidation
          name="name"
          control={form.control}
          label="Automation Name"
          rules={{ required: "Automation name is required" }}
          placeholder="Descriptive name for the automation"
        />
      </Grid.Item>
      <Grid.Item xs={12}>
        <SelectWithValidation
          name="industries"
          control={form.control}
          data={industry_options.data}
          isLoading={industry_options.isLoading}
          label="ANSZIC Industries"
          rules={{
            required: validation_rules.select.required(
              "One or more industries are required",
            ),
          }}
          searchable={setIndustrySearchTerm}
          placeholder="Industry fields"
          multiple={true}
          filterFn={() => true} // disable static filtering
        />
      </Grid.Item>
      <Grid.Item xs={12}>
        <SelectWithValidation
          name="toolkits"
          control={form.control}
          data={toolkit_options.data}
          isLoading={toolkit_options.isLoading}
          label="Toolkits"
          placeholder="Toolkit codes"
          rules={{
            required: validation_rules.select.required(
              "One or more toolkits are required",
            ),
          }}
          searchable={setToolkitSearchTerm}
          multiple={true}
          filterFn={() => true} // disable static filtering
        />
      </Grid.Item>
      <Grid.Item xs={12}>
        <Spacing $height={24} />
        <Grid.Item xs={12} style={{ display: "flex", alignItems: "center" }}>
          <Controller
            control={form.control}
            name="enabled"
            render={({ field: enabled_field }) => (
              <CheckboxItem
                size="lg"
                checked={enabled_field.value}
                onChange={enabled_field.onChange}
              >
                Activate automation {type === "Create" ? "on creation" : ""}
              </CheckboxItem>
            )}
          />
        </Grid.Item>
        <Grid.Item xs={12} style={{ marginTop: 32 }}>
          {type === "Edit" && (
            <Button
              type="button"
              variant="secondary"
              style={{ marginRight: 24 }}
              onClick={onCancel}
            >
              Cancel
            </Button>
          )}
          <Button type="button" onClick={handleSubmit} variant="primary">
            {has_submitted ? <Spinner color="white" /> : submit_button_text}
          </Button>
        </Grid.Item>
      </Grid.Item>
    </Grid>
  );
};
