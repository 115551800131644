import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { BrokerInvitationFields } from "./BrokerInvitationFields.models";
import { Button, Typography } from "@safetyculture/sc-web-ui";
import { InputWithValidation } from "../../../components/form/InputWithValidation";
import { FormRow, InviteFormGrid } from "../styles.css";
import { validation_rules } from "../../../components/form/validation_rules";
import { Container } from "../../../components/util";

type Props = {
  onSubmit: (fields: BrokerInvitationFields) => Promise<void>;
};

export const BrokerInviteForm: React.FC<Props> = ({ onSubmit }) => {
  const form = useForm<BrokerInvitationFields>({
    defaultValues: {
      firstname: "",
      lastname: "",
      email: "",
    },
    mode: "all",
  });

  return (
    <FormProvider {...form}>
      <Typography variant="titleLarge">Broker details</Typography>
      <Container>
        <InviteFormGrid>
          <FormRow>
            <Container className="column">
              <InputWithValidation
                name="firstname"
                rules={{
                  required: { value: true, message: "First name is required" },
                }}
                label="First name"
                placeholder="First name"
                control={form.control}
              />
            </Container>
            <Container className="column">
              <InputWithValidation
                name="lastname"
                rules={{
                  required: { value: true, message: "Last name is required" },
                }}
                label="Last name"
                placeholder="Last name"
                control={form.control}
              />
            </Container>
          </FormRow>
          <InputWithValidation
            name="email"
            rules={{
              required: { value: true, message: "Email address is required" },
              pattern: {
                value: validation_rules.patterns.email,
                message: "Invalid email address",
              },
            }}
            label="Email"
            placeholder="Email address"
            control={form.control}
            margin={{ top: 24, bottom: 24 }}
          />
        </InviteFormGrid>

        <InviteFormGrid>
          <Button
            type="button"
            onClick={form.handleSubmit(onSubmit)}
            style={{ marginTop: 32 }}
            variant="primary"
            disabled={form.formState.isSubmitting}
          >
            Submit
          </Button>
        </InviteFormGrid>
      </Container>
    </FormProvider>
  );
};
